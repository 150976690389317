@tailwind base;
@tailwind components;
@tailwind utilities

.App {
  text-align: center;
}

table.spaced_table tbody td, th {
  margin: 0;
  padding: 0.25em;
  padding-right: 0.5em;
  padding-left: 0.5em;
}

table.spaced_table {
  white-space: nowrap;
}

.scorecol {
  text-align: right;
  width: 30px;
}

.sidecol {
  width: 40px;
}

.pastchamps {
  border: 0;
  border-collapse: collapse;
  margin: 5px 0 5px 0;
}

.pastchamps td, th {
  vertical-align: top;
  text-align: left;
  padding: 3px 20px 3px 5px;
}

.centerrowicon {
  vertical-align: middle !important;
  white-space: nowrap !important;
}

.centerrowicon img {
  display: inline;
}